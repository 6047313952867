import config from "@/services/config";
import Pusher, { Channel } from "pusher-js";
import { watch } from "vue";

import auth0 from "@/auth0";

let pusher: Pusher | null = null;

export function initializePusher() {
  watch(
    auth0.isAuthenticated,
    async (isAuthenticated) => {
      if (isAuthenticated) {
        const token = await auth0.getAccessTokenSilently();
        pusher = new Pusher(config.PUSHER_KEY, {
          wsHost: config.PUSHER_HOST,
          wsPort: config.PUSHER_PORT,
          wsPath: "/ws",
          forceTLS: config.PUSHER_TLS,
          disableStats: true,
          enabledTransports: ["ws", "wss"],
          cluster: "",
          channelAuthorization: {
            endpoint: "/api/pusher/auth",
            transport: "ajax",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        });
      }
    },
    { immediate: true },
  );
}

export function subscribe(channelName: string, attempts = 0): Promise<Channel> {
  return new Promise((resolve, reject) => {
    if (!pusher) {
      reject(new Error("Pusher is not initialized"));
    }

    const channel = pusher!.subscribe(`private-${channelName}`);

    const subscriptionTimeoutId = setTimeout(
      () => reject(new Error("Pusher subscription timed out")),
      10_000,
    );

    channel.bind("pusher:subscription_succeeded", () => {
      clearTimeout(subscriptionTimeoutId);
      resolve(channel);
    });

    channel.bind(
      "pusher:subscription_error",
      (error: { error: string; status: number }) => {
        clearTimeout(subscriptionTimeoutId);

        if (attempts < 3 && (error.status === 408 || error.status === 503)) {
          setTimeout(
            () => subscribe(channelName, attempts + 1).then(resolve, reject),
            1_000 * attempts,
          );
          return;
        }

        reject(new Error(`Pusher subscription error: ${error.error}`));
      },
    );
  });
}

export function unsubscribe(channelName: string) {
  if (!pusher) {
    throw new Error("Pusher is not initialized");
  }
  pusher.unsubscribe(`private-${channelName}`);
}

export function bind(
  channel: Channel,
  eventName: string,
  callback: (event: any) => void,
) {
  if (!pusher) {
    throw new Error("Pusher is not initialized");
  }
  return channel.bind(eventName, callback);
}

export function bindOnce(
  channel: Channel,
  eventName: string,
  callback: (event: any) => void,
) {
  if (!pusher) {
    throw new Error("Pusher is not initialized");
  }

  channel.bind(eventName, (event: any) => {
    callback(event);
    channel.unbind(eventName);
  });
}
