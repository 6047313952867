import Platform from "@/views/Platform.vue";
import Home from "@/views/Home.vue";

import { permissionGuard } from "@/composeables/useAuth";
import { authGuard } from "@auth0/auth0-vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "",
    children: [
      {
        path: "/",
        name: "home",
        component: Home,
      },
      {
        path: "/home",
        redirect: { name: "home" },
      },
      {
        path: "/advertiser",
        name: "advertiser",
        component: () => import("@/views/advertiser/Advertiser.vue"),
        meta: { requiresPermission: "view:advertiser" },
      },
      {
        path: "/ad-ops-automation",
        name: "ad-ops-automation",
        component: () => import("@/views/ad-ops-automation/AdOpsPortal.vue"),
      },
      {
        path: "/health",
        name: "health",
        component: () => import("@/views/health/Health.vue"),
        meta: { requiresPermission: "view:app-status" },
      },
      {
        path: "/logs",
        component: () => import("@/views/logs/Logs.vue"),
        meta: { requiresPermission: "view:logs" },
        children: [
          {
            path: "",
            name: "logs",
            redirect: "logs/bidding",
          },
          {
            path: "bidding",
            name: "logs.bidding",
            component: () => import("@/views/logs/BidAdjustment.vue"),
          },
          {
            path: "scheduling",
            name: "logs.scheduling",
            component: () => import("@/views/logs/Scheduling.vue"),
          },
          {
            path: "budget-allocation",
            component: () =>
              import("@/views/logs/budget-allocation/component.vue"),
            children: [
              {
                path: "",
                name: "logs.budget-allocation",
                redirect: "budget-allocation/facebook",
              },
              {
                path: "facebook",
                name: "logs.budget-allocation.facebook",
                component: () =>
                  import("@/views/logs/budget-allocation/facebook.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/platform-automation",
        component: () =>
          import("@/views/platform-automation/PlatformAutomation.vue"),
        meta: { requiresPermission: "view:platform-automation" },
        children: [
          {
            path: "nav",
            name: "platform-automation",
            component: () =>
              import(
                "@/views/platform-automation/PlatformAutomationNavHub.vue"
              ),
          },
          {
            path: "ad-creator",
            name: "ad-creator",
            component: () =>
              import("@/views/platform-automation/AdCreator.vue"),
          },
          {
            path: "raw-upload",
            name: "raw-upload",
            component: () =>
              import("@/views/platform-automation/RawUpload.vue"),
          },
          {
            path: "budget-pacing",
            name: "budget-pacing",
            component: () =>
              import(
                "@/views/platform-automation/budget-pacing/BudgetPacingTable.vue"
              ),
          },
          {
            path: "advertiser-automation-settings",
            name: "advertiser-automation-settings",
            component: () =>
              import(
                "@/views/platform-automation/automation/AdvertiserAutomationSettings.vue"
              ),
          },
          {
            path: "line-item-schedule-config",
            name: "line-item-schedule-config",
            component: () =>
              import(
                "@/views/platform-automation/line-item-schedule/LineItemScheduleTable.vue"
              ),
          },
          {
            path: "schedule-config",
            name: "schedule-config",
            component: () =>
              import(
                "@/views/platform-automation/line-item-schedule/ScheduleTable.vue"
              ),
          },
          {
            path: "line-item-bid-adjustment-config",
            name: "line-item-bid-adjustment-config",
            component: () =>
              import(
                "@/views/platform-automation/line-item-bid-adjustment/LineItemBidAdjustmentTable.vue"
              ),
          },
          {
            path: "phone-number",
            name: "phone-number",
            component: () =>
              import("@/views/platform-automation/PhoneNumber.vue"),
          },
          {
            path: "ooh",
            name: "ooh-automation",
            component: () =>
              import(
                "@/views/platform-automation/ooh-automation/OOHAutomation.vue"
              ),
            meta: { requiresPermission: "read:ooh" },
          },
        ],
      },
      {
        // preserve previous path in case of user bookmarks
        path: "/ooh-automation",
        redirect: "/platform-automation/ooh",
      },
      {
        path: "/platform",
        component: Platform,
        meta: { requiresPermission: "view:platform" },
        children: [
          {
            path: "/",
            name: "platform",
            redirect: "platform/creatives",
          },
          {
            path: "creatives",
            name: "platform.creatives",
            component: () => import("@/views/platform-creatives/index.vue"),
          },
        ],
      },
      {
        path: "/qa",
        name: "qa",
        component: () => import("@/views/qa-automation/QaCheckList.vue"),
        meta: { requiresPermission: "view:qa" },
      },
      {
        path: "/platform-qa",
        name: "platform-qa",
        component: () => import("@/views/platform-qa/PlatformQaCheckList.vue"),
        meta: { requiresPermission: "view:qa" },
      },
      {
        path: "/qa/platformterms",
        name: "qa-platform-terms",
        component: () => import("@/views/qa-automation/QaPlatformTermList.vue"),
        meta: { requiresPermission: "view:qa" },
      },
      {
        path: "/qa/landing-pages",
        name: "landing-page-qa",
        component: () => import("@/views/landing-page-qa/LandingPageQA.vue"),
        meta: { requiresPermission: "view:qa" },
      },
      {
        path: "/qa/landing-pages/edit-url/:urlId?",
        name: "landing-page-qa-edit-url",
        component: () => import("@/views/landing-page-qa/EditURL.vue"),
        meta: { requiresPermission: "view:qa" },
      },
      {
        path: "/qa/platformcheck/report/:platformQaCheckID",
        name: "qa-platform-check-report",
        component: () =>
          import(
            "@/views/qa-automation/platform-reports/PlatformQaCheckReport.vue"
          ),
        meta: { requiresPermission: "view:qa" },
      },
      {
        path: "/qa/check/:qaCheckId",
        name: "qa-check",
        component: () => import("@/views/qa-automation/QaCheckDetail.vue"),
        meta: { requiresPermission: "view:qa" },
        props: true,
      },
      {
        path: "/amazon-dsp/campaign-name-map",
        name: "tool.amazon-dsp.campaign-name-map",
        component: () =>
          import("@/views/amazon-dsp/campaign-name-map/Page.vue"),
        meta: { requiresPermission: "amazon-dsp:campaign-name-map" },
        props: true,
      },
      {
        path: "/cross-channel-budget-allocation",
        name: "cross-channel-budget-allocation",
        component: () =>
          import("@/views/cross-channel-budget-allocation/CCBA.vue"),
      },
      {
        path: "/cross-channel-budget-allocation/:campaignId",
        name: "cross-channel-budget-allocation-campaign",
        component: () =>
          import("@/views/cross-channel-budget-allocation/CCBACampaign.vue"),
        props: true,
      },
      {
        path: "/cross-channel-budget-allocation-channel",
        name: "cross-channel-budget-allocation-channel",
        component: () =>
          import("@/views/cross-channel-budget-allocation/Channel.vue"),
        meta: {
          requiresPermission: "cross-channel-budget-allocation:update_channel",
        },
      },
      {
        path: "/post-log-aggregation",
        name: "post-log-aggregation",
        component: () =>
          import("@/views/post-log-aggregation/PostLogAggregation.vue"),
        meta: {},
      },
      {
        path: "/post-log-aggregation/network-formats",
        name: "post-log-aggregation.network-formats",
        component: () =>
          import("@/views/post-log-aggregation/NetworkFormats.vue"),
        meta: {},
      },
      {
        path: "/post-log-aggregation/uncategorized-emails",
        name: "post-log-aggregation.uncategorized-emails",
        component: () =>
          import("@/views/post-log-aggregation/UncategorizedEmails.vue"),
        meta: {},
      },
      {
        path: "/audience-import",
        name: "audience-import",
        component: () =>
          import("@/views/audience-import/AudienceImportLanding.vue"),
      },
      {
        path: "wise/top-tv",
        name: "wise-top-tv",
        component: () => import("@/views/wise/TopTv.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(authGuard);
router.beforeEach(permissionGuard);

export default router;
export function useRoutes() {
  return routes;
}
